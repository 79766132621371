<template>
  <div class="page_bg" style="margin:0;">
    <!-- 顶部搜索和banner -->
    <!-- 顶部搜索 -->
    <div class="study_top flex-r-c-c">
      <van-search v-model="searchValue" class="classify_ser" placeholder="请输入课程名称或关键字搜索" disabled shape="round"
                  @click="$router.push('/studyClass/studySer')"
      >
        <template #right-icon>
          <van-button round color="#0767AB" size="small" style="width:1.49rem;height:0.69rem;margin-top:-0.1rem" @click="$router.push('/studyClass/studySer')">搜索</van-button>
          <!-- <van-image width="1.49rem" height="0.6rem" src="https://oss.xiaoyi120.com/shop2.0/public/ser_btn.png" /> -->
        </template>
      </van-search>
      <!-- <div class="classify_btn" @click="$router.push('/product/searchpro')">
            <van-button round color="#0767AB" size="small">搜索</van-button>
          </div> -->
    </div>
    <!-- 轮播图 -->
    <div class="study_banner">
      <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(banner,index_banner) in info" :key="index_banner">
          <van-image width="9.36rem" height="3.47rem" :src="banner.imageUrl" fit="fill" style="border-radius: 0.3rem;"
                     @click="jumpUrl(banner)"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 视频专区 -->
    <div class="study_project">
      <van-row>
        <van-col :span="6" @click="classifyJump(0)">
          <van-image width="1.17rem" height="1.17rem"
                     src="https://oss.xiaoyi120.com/shop2.0/study/study_card_01.png"
          />
          <div class="study_project_title">医院管理</div>
        </van-col>
        <van-col :span="6" @click="classifyJump(1)">
          <van-image width="1.17rem" height="1.17rem"
                     src="https://oss.xiaoyi120.com/shop2.0/study/study_card_02.png"
          />
          <div class="study_project_title">骨科知识</div>
        </van-col>
        <van-col :span="6" @click="classifyJump(2)">
          <van-image width="1.17rem" height="1.17rem"
                     src="https://oss.xiaoyi120.com/shop2.0/study/study_card_03.png"
          />
          <div class="study_project_title">动物麻醉</div>
        </van-col>
        <van-col :span="6" @click="classifyJump(3)">
          <van-image width="1.17rem" height="1.17rem"
                     src="https://oss.xiaoyi120.com/shop2.0/study/study_card_04.png"
          />
          <div class="study_project_title">生化判读</div>
        </van-col>
      </van-row>
    </div>
    <!-- 商品列表区域 -->
    <div class="index_pro_list">
      <div class="pro_top_type flex-r-s-c">
        <div :class="tabindex === '1'?'type_names type_actived1':'type_names'" @click="proTypeSer('1')">推荐课程</div>
        <div :class="tabindex === '2'?'type_names type_actived1':'type_names'" @click="proTypeSer('2')">我的课程</div>
        <div :class="tabindex === '3'?'type_names type_actived1':'type_names'" @click="proTypeSer('3')">我的收藏</div>
      </div>
      <template v-if="tabindex !== '2'">
        <MyscrollList ref="myList01" :loading="listLoading" :list="proList" :line-type="1" @myload="proload">
          <template #default="slotProps">
            <classCard01 :info="slotProps.item" />
          </template>
        </MyscrollList>
      </template>
      <template v-else>
        <MyscrollList ref="myList02" :loading="listLoading2" :list="proList2" :line-type="1" @myload="proload2">
          <template #default="slotProps">
            <div class="class_order_list">
              <div class="study_order_info_top flex-r-sb-c">
                <div class="top_no">{{ slotProps.item.orderId }}</div>
                <div v-if="slotProps.item.status !== 0" class="top_no">{{ slotProps.item.createTime }}</div>
                <div v-else class="top_no">
                  <van-count-down :time="slotProps.item.time" format="mm:ss" style="color:#fff;font-weight:bold;font-size:0.48rem" />
                </div>
              </div>
              <classCard02 :id="slotProps.item.orderId" :info="slotProps.item.cloudRoomCourse" :status="slotProps.item.status" />
              <div class="study_order_info_bottom flex-r-sb-c">
                <div class="bottom_price">支付金额：￥{{ slotProps.item.payMoney }}</div>
                <div class="bottom_time">{{ slotProps.item.effectiveTime }}</div>
              </div>
            </div>
          </template>
        </MyscrollList>
      </template>
    </div>
    <!-- 底部留白 -->
    <div class="bottom_blank" />
    <!-- Tabber -->
    <Tabber ref="tabber" :index="5" />
  </div>
</template>

<script>
import './index.scss'
import Tabber from '@/components/tabber'
import MyscrollList from '@/components/MyscrollList'
import classCard01 from '@/components/class_card_01'
import classCard02 from '@/components/class_card_02'

import { classProject } from '@/utils/constants'
import { cloudroom_banner, course_list, cloudroom_queryOrderList } from '@/api/study'
export default {
  name: 'Index',
  components: { Tabber, MyscrollList, classCard01, classCard02 },
  data() {
    return {
      tabindex: '1',
      searchValue: '',
      info: {}, // banner，discount
      // 商品列表
      proSearch: { page: 1, limit: 10 },
      proList: [],
      listLoading: false,
      // 商品列表
      proSearch2: { page: 1, limit: 10 },
      proList2: [],
      listLoading2: false

    }
  },
  created() {
    this.getData()
    this.getProList()
    this.getProList2()
    this.$utils.cartNum(this.$store)
  },
  onShow() {
    this.$utils.cartNum(this.$store)
  },
  methods: {
    // 获取banner，discount
    getData() { cloudroom_banner().then(res => { this.info = res.data }) },
    // 获取推荐的课程
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      course_list(this.proSearch, this.tabindex).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.list)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.page = this.proSearch.page + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    },
    getProList2() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      cloudroom_queryOrderList(this.proSearch2).then(res => {
        this.$toast.clear()
        this.$refs.myList02.load_loading(false)
        for (const i in res.data.list) {
          if (res.data.list[i].status === 0) {
            // 倒计时
            const startTime = new Date().getTime()
            const endTime = new Date(res.data.list[i].expireTime).getTime()
            this.$set(res.data.list[i], 'time', endTime - startTime)
          }
        }
        this.proList2 = this.proList2.concat(res.data.list)
        if (parseInt(res.data.total) === 0) { this.$refs.myList02.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList02.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload2() {
      this.$refs.myList02.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch2.page = this.proSearch2.page + 1
        this.getProList2()
        // 加载状态结束
      }, 50)()
    },
    // 课程顶部三个type查询
    proTypeSer(type) {
      this.tabindex = type
      this.proList = []
      this.proList2 = []
      this.proSearch.page = 1
      this.proSearch2.page = 1
      if (parseInt(type) === 2) {
        this.getProList2()
        this.$refs.myList01.load_status_clear()
      } else {
        this.getProList()
        this.$refs.myList02.load_status_clear()
      }
    },
    // 活动 banner 跳转事件
    jumpUrl(item) { this.$router.push('/studyClass/studyDetail?id=' + item.cloudRoomCourseId) },
    // 分类 品牌 跳转
    classifyJump(index) { this.$router.push('/studyClass/studyDetail?id=' + classProject[index]) }
  }
}
</script>
