<template>
  <div class="class_project_box" style="min-height:0rem">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.93rem" height="1.87rem" :src="myinfo.thumbnail" style="margin-left: 0.27rem;"
                 @click="(e) => { $router.push('/studyClass/studyDetail?id=' + myinfo.cloudRoomCourseId) }"
      />
      <div style="margin-left:0.2rem">
        <div class="pro_name van-ellipsis"
             @click="(e) => { $router.push('/studyClass/studyDetail?id=' + myinfo.cloudRoomCourseId) }"
        >{{ myinfo.name }}
        </div>
        <div class="pro_brand_model van-ellipsis"
             @click="(e) => { $router.push('/studyClass/studyDetail?id=' + myinfo.cloudRoomCourseId) }"
        >
          {{ myinfo.description }} </div>
        <div class="pro_bottom flex-r-sb-c">
          <div class="pro_money">￥<span style="font-size: 0.49rem;">{{ myinfo.salePrice }}</span></div>
          <div class="pro_btn flex-r-c-c">
            <van-icon name="clock-o" style="padding-right:0.1rem" />  {{ $utils.getHMS(myinfo.time) }}
            <van-icon name="play-circle-o" style="margin-left:0.43rem;padding-right:0.1rem" />  {{ myinfo.playNum }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: { type: Object, default: () => { } }
  },
  data() {
    return {
      myinfo: this.info
    }
  },
  methods: {

  }
}
</script>
